<template>
  <div>
    <v-simple-table
      class="table-padding-2"
      fixed-header
      height="calc(100vh - 205px)"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 150px">
              <SelectWarehouse
                class="c-input-xs input-filter"
                :label="$t('labels.warehouse_1')"
                :placeholder="$t('labels.warehouse_1')"
                :auto-select="false"
                name="id_warehouse"
                @onFilter="onFilterChange"
              />
            </th>
            <th class="text-center" style="width: 150px">
              <SelectFilter
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                :options="allowDeliveryCompanies"
                name="id_delivery_company"
                @onFilter="onFilterChange"
              />
            </th>
            <th class="text-center" style="width: 150px">
              <InputFilter
                :label="$t('labels.company_id')"
                :placeholder="$t('labels.company_id')"
                name="company_id"
                @onFilter="onFilterChange"
              />
            </th>
            <th class="text-center" style="width: 150px">
              <InputFilter
                :label="$t('labels.customer_id')"
                :placeholder="$t('labels.customer_id')"
                name="customer_id"
                @onFilter="onFilterChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.token')"
                :placeholder="$t('labels.token')"
                name="token"
                @onFilter="onFilterChange"
              />
            </th>
            <th class="text-center" style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="yesNoOptions"
                name="status"
                @onFilter="onFilterChange"
              />
            </th>
            <!-- <th class="text-center">{{ $t("labels.api_fee") }}</th> -->
            <th class="text-center" style="width: 100px">
              <v-btn color="primary" small @click="addItem">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <select-warehouse-model
                v-model="item.id_warehouse"
                single-line
                label=""
                :disabled="!item.editing"
              ></select-warehouse-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_delivery_company"
                :items="allowDeliveryCompanies"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing"
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                class="c-input-small"
                v-model="item.company_id"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="c-input-small"
                v-model="item.customer_id"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="c-input-small"
                v-model="item.token"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing"
              ></v-text-field>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <!-- <td></td> -->
            <td>
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="saveContract(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        v-if="totalPage > 1"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import {
  ID_DELIVERY_GHN,
  ID_DELIVERY_GHTK,
  ID_DELIVERY_JNT,
  ID_DELIVERY_SPX,
  ID_DELIVERY_VTP,
  ID_DELIVERY_VNP,
  ID_DELIVERY_NJV,
  ALLOW_FIELD_DELIVERY_ACCOUNT_GHN,
  ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK,
  ALLOW_FIELD_DELIVERY_ACCOUNT_JNT,
  ALLOW_FIELD_DELIVERY_ACCOUNT_SPX,
  ALLOW_FIELD_DELIVERY_ACCOUNT_VNP,
  ALLOW_FIELD_DELIVERY_ACCOUNT_VTP,
  ALLOW_FIELD_DELIVERY_ACCOUNT_NJV,
} from "@/libs/const";

export default {
  name: "Delivery",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    filters: {},
    page: 1,
    totalPage: 1,
  }),
  computed: {
    allowFields() {
      switch (this.idDeliveryCompany) {
        case ID_DELIVERY_GHN:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_GHN;
        case ID_DELIVERY_GHTK:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK;
        case ID_DELIVERY_VNP:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_VNP;
        case ID_DELIVERY_JNT:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_JNT;
        case ID_DELIVERY_SPX:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_SPX;
        case ID_DELIVERY_VTP:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_VTP;
        case ID_DELIVERY_NJV:
          return ALLOW_FIELD_DELIVERY_ACCOUNT_NJV;
        default:
          return [];
      }
    },
    allowDeliveryCompanies() {
      return [
        {
          value: ID_DELIVERY_GHN,
          text: "GHN",
        },
        {
          value: ID_DELIVERY_GHTK,
          text: "GHTK",
        },
        {
          value: ID_DELIVERY_VNP,
          text: "VNPost",
        },
        {
          value: ID_DELIVERY_JNT,
          text: "JnT",
        },
        {
          value: ID_DELIVERY_SPX,
          text: "SPX",
        },
        {
          value: ID_DELIVERY_VTP,
          text: "VIETTEL",
        },
        {
          value: ID_DELIVERY_NJV,
          text: "Ninjavan",
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  async mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        id_warehouse: null,
        id_delivery_company: null,
        token: null,
        shop_id: null,
        user_id: null,
        user_secret: null,
        company_id: null,
        customer_id: null,
        username: null,
        password: null,
        service: null,
        status: 1,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async getList() {
      try {
        const { data } = await httpClient.post("/delivery-contract-list", {
          ...this.filters,
          page: this.page,
        });
        this.totalPage = data.totalPage;
        this.items = [...data.rows].map((row) => {
          const account = row.account_info ? JSON.parse(row.account_info) : {};
          return {
            ...row,
            ...account,
          };
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async saveContract(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-contract", {
          ...item,
          account_info: {
            token: item.token,
            shop_id: item.shop_id,
            user_id: item.user_id,
            user_secret: item.user_secret,
            company_id: item.company_id,
            customer_id: item.customer_id,
            username: item.username,
            password: item.password,
            service: item.service,
          },
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>
